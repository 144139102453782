<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import {
  HeadingElement,
  HeadingSize,
} from '../types';

export default defineComponent({
  props: {
    element: {
      type: String,
      validator: (val: HeadingElement): boolean =>
        Object.values(HeadingElement).includes(val),
      default: HeadingElement.H2,
    },

    size: {
      type: String,
      validator: (val: HeadingSize): boolean =>
        Object.values(HeadingSize).includes(val),
      default: HeadingSize.Medium,
    },
  },

  setup(props) {
    const classList = computed(() => {
      if (props.size === HeadingSize.ExtraSmall) {
        return 'text-xs';
      }

      if (props.size === HeadingSize.Small) {
        return 'text-md font-medium';
      }

      if (props.size === HeadingSize.Medium) {
        return 'text-lg font-medium leading-6';
      }

      if (props.size === HeadingSize.Large) {
        return 'text-2xl font-medium';
      }

      if (props.size === HeadingSize.ExtraLarge) {
        return 'text-3xl font-medium';
      }

      return '';
    });

    return {
      classList,
    };
  },
});
</script>

<template>
  <component
    :is="element"
    :class="classList"
  >
    <slot />
  </component>
</template>
