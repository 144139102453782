export enum LinkVariant {
  Default = 'default',
  Critical = 'critical',
  Subdued = 'subdued',
}

export enum LinkFontSize {
  Default = 'default',
  Small = 'small',
}
