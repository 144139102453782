export enum HeadingElement {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
}

export enum HeadingSize {
  Small = 'Small',
  Medium = 'medium',
  Large = 'large',
}

export enum HeadingWeight {
  Medium = 'medium',
  Bold = 'bold',
}
