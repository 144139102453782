<script lang="ts" setup>
import { PopoverPlacement } from '../types';
import { computed, PropType, ref, watch } from 'vue';
import { createPopper } from '@popperjs/core';
import { isNull, max } from 'lodash';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },

  flush: {
    type: Boolean,
    default: false,
  },

  parent: {
    type: Object as PropType<HTMLElement>,
    default: null,
  },

  placement: {
    type: String as PropType<PopoverPlacement>,
    validator: (val: PopoverPlacement) => {
      return Object.values(PopoverPlacement).includes(val);
    },
    default: PopoverPlacement.BottomStart,
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },

  minWidth: {
    type: Number,
    default: null,
  },

  offset: {
    type: Number,
    default: 8,
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function closePopover() {
  if (!props.active) {
    return;
  }

  emit('close');
}

const classList = computed(() => {
  return [
    { 'p-2': !props.flush },
  ];
});

const popover = ref<HTMLElement>(null);

function initializePopper() {
  createPopper(props.parent, popover.value, {
    placement: props.placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, props.offset],
        },
      },
      {
        name: 'sameWidth',
        enabled: props.fullWidth,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn: ({ state }) => {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
      },
      {
        name: 'minWidth',
        enabled: !isNull(props.minWidth),
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn: ({ state }) => {
          const minWidth = max([state.rects.reference.width, props.minWidth]);

          state.styles.popper.minWidth = `${minWidth}px`;
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: [],
        },
      },
    ],
  });
}

watch(() => props.active, (active) => {
  if (active && !isNull(props.parent)) {
    initializePopper();
  }
}, { immediate: true });
</script>

<template>
  <div
    v-show="active"
    ref="popover"
    class="z-[100] absolute"
  >
    <div
      v-if="active"
      v-click-away="closePopover"
      class="overflow-hidden border-slate-300 rounded-md border bg-white shadow-lg"
      :class="classList"
    >
      <slot />
    </div>
  </div>
</template>
