import { ButtonType, ButtonVariant } from '../Button';

export enum FooterActionPosition {
  Left = 'left',
  Right = 'right',
  FullWidth = 'full-width',
  Between = 'between',
}

export interface PrimaryFooterAction {
  content: string;
  type?: ButtonType;
  callback?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export interface SecondaryFooterAction {
  content: string;
  disabled?: boolean;
  variant?: ButtonVariant;
  type?: ButtonType;
  plain?: boolean;
  loading?: boolean;
  callback?: () => void;
}

export enum CardShadow {
  Default = 'default',
  ExtraLarge = 'extra-large',
}
