<script lang="ts" setup>
import {
  computed,
  PropType,
  useSlots,
} from 'vue';
import { InputLabel } from '../../InputLabel';
import { ErrorMessage } from '../../ErrorMessage';
import { HelpText } from '../../../Components/HelpText';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { Option } from '../index';
import { theme } from '../../../Composables/useTheme';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },

  options: {
    type: Array as PropType<Option[]>,
    default: () => ([]),
  },

  id: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    default: null,
  },

  labelFor: {
    type: String,
    default: null,
  },

  labelHidden: {
    type: Boolean,
    default: false,
  },

  name: {
    type: String,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  helpText: {
    type: String,
    default: null,
  },

  placeholder: {
    type: String,
    default: null,
  },

  error: {
    type: String,
    default: null,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'change',
]);

const elementId = computed((): string => props.id || useUniqueId('select'));

const classList = computed(() => {
  const placeholderColor = props.modelValue === null || props.modelValue === ''
    ? 'text-slate-400'
    : 'text-slate-700';

  return [
    placeholderColor,
    theme([
      'border',
      'outline',
    ], props.error ? 'critical' : 'default'),
  ];
});

const onItemSelected = (event: InputEvent): void => {
  const targetValue = (event.target as HTMLSelectElement).value;

  emit('update:modelValue', targetValue);
  emit('change', event);
};

const slots = useSlots();

const hasHelpText = computed<boolean>(() => {
  return !!props.helpText || !!slots['help-text'];
});
</script>

<template>
  <div class="relative w-full text-sm">
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="elementId"
      :label-hidden="labelHidden"
      :flush="hasHelpText"
    />

    <HelpText v-if="hasHelpText">
      <slot name="help-text">
        {{ helpText }}
      </slot>
    </HelpText>

    <select
      :id="elementId"
      :disabled="disabled"
      :value="modelValue"
      data-test="select"
      :class="classList"
      class="text-sm relative pr-10 bg-white block w-full rounded-md border px-3 py-2 shadow-sm cursor-pointer select-none font-normal appearance-none"
      @change="onItemSelected"
    >
      <option
        v-if="placeholder"
        value=""
        selected
        disabled
      >
        {{ placeholder }}
      </option>

      <option
        v-for="(option, index) in options"
        :key="index"
        class="text-slate-700"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>
