<script lang="ts" setup>
import {
  computed,
  onMounted,
  PropType,
  ref,
  useSlots,
  watch,
} from 'vue';
import ErrorMessage from '../../ErrorMessage/components/ErrorMessage.vue';
import { HelpText } from '../../HelpText';
import { InputLabel } from '../../InputLabel';
import { useUniqueId } from '../../../Composables/useUniqueId';
import {
  AutocompleteOption,
  FieldTypes,
  InputFontFamily,
  InputIconPosition,
  InputTextSize,
} from '../types';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { theme } from '../../../Composables/useTheme';
import { useClipboard } from '../../../Composables/useClipboard';
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline';
import { Option as SelectOption } from '../../Select';
import { ToastVariant } from '../../Toast';
import { useToast } from '../../../Composables/useToast';
import { TextInputFieldTypes } from '../..';
import { Tooltip } from '../../Tooltip';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },

  allowCopy: {
    type: Boolean,
    default: false,
  },

  ariaControls: {
    type: String,
    default: null,
  },

  autofocus: {
    type: Boolean,
    default: false,
  },

  focussed: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  id: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    default: null,
  },

  labelHidden: {
    type: Boolean,
    default: false,
  },

  helpText: {
    type: String,
    default: null,
  },

  placeholder: {
    type: String,
    default: null,
  },

  type: {
    type: String,
    default: FieldTypes.Text,
    validator: (value: string): boolean =>
      Object.values(FieldTypes).includes(value as FieldTypes),
  },

  autocomplete: {
    type: String,
    default: AutocompleteOption.Off,
    validator: (val: AutocompleteOption): boolean =>
      Object.values(AutocompleteOption).includes(val),
  },

  required: {
    type: Boolean,
    default: false,
  },

  name: {
    type: String,
    default: null,
  },

  maxLength: {
    type: Number,
    default: 5_000,
  },

  fontFamily: {
    type: String,
    validator: (val: InputFontFamily): boolean =>
      Object.values(InputFontFamily).includes(val),
    default: InputFontFamily.Sans,
  },

  textSize: {
    type: String,
    validator: (val: InputTextSize): boolean =>
      Object.values(InputTextSize).includes(val),
    default: InputTextSize.Small,
  },

  error: {
    type: String,
    default: null,
  },

  iconPosition: {
    type: String,
    validator: (val: InputIconPosition) =>
      Object.values(InputIconPosition).includes(val),
    default: InputIconPosition.Right,
  },

  selectable: {
    type: Boolean,
    default: false,
  },

  selected: {
    type: String,
    default: null,
  },

  options: {
    type: Array as PropType<SelectOption[]>,
    default: null,
  },

  allowDecimal: {
    type: Boolean,
    default: false,
  },

  max: {
    type: Number,
    default: null,
  },

  min: {
    type: Number,
    default: null,
  },

  showTooltipError: {
    type: Boolean,
    default: false,
  },
});

const elementId = computed(() => props.id || useUniqueId('textField'));
const input = ref(null);
onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });
const inputTextSizeClass = computed(() => {
  if (props.textSize === InputTextSize.Large) {
    return 'text-xl';
  }
  return 'text-sm';
});

const hasAddon = computed(() => {
  return !!slots['addon'];
});

const borderColorClass = computed(() => {
  return [
    theme(
      ['border', 'focus-within-border-color', 'focus-within-ring-color'],
      props.error ? 'critical' : 'default',
    ),
    theme('border-size'),
    theme('focus-within-ring-size'),
    hasAddon.value ? 'rounded-r-none' : '',
  ];
});

const inputPaddingClass = computed(() => {
  if (props.type === FieldTypes.Color) {
    return 'p-[0.1rem]';
  }
  return 'px-3 py-2';
});

const inputMarginClass = computed(() => {
  if (props.error || (hasIcon.value && props.iconPosition === InputIconPosition.Right)) {
    return 'mr-6';
  }
  return '';
});

const slots = useSlots();
const inputIconPaddingClass = computed(() => {
  if (props.selectable) {
    return 'pr-12';
  }

  if (!slots['icon']) {
    if (props.type === FieldTypes.Number) {
      return 'pr-3';
    }
    if (props.type === FieldTypes.Date) {
      return 'pr-3';
    }
    return '';
  }

  if (props.iconPosition === InputIconPosition.Right && props.error) {
    return 'pr-16';
  }

  if (props.iconPosition === InputIconPosition.Left && props.error) {
    return 'pl-8 px-10';
  }

  if (props.iconPosition === InputIconPosition.Right && !props.error) {
    return 'pr-10';
  }

  if (props.iconPosition === InputIconPosition.Left && !props.error) {
    return 'pl-8';
  }

  return '';
});

const iconPositionClass = computed(() => {
  if (props.iconPosition === InputIconPosition.Right && props.error) {
    return 'right-9';
  }
  if (props.iconPosition === InputIconPosition.Right && !props.error) {
    return 'right-3';
  }
  return 'left-3';
});

const InputFontFamilyClass = computed(() => {
  return props.fontFamily;
});

const emit = defineEmits(['update:modelValue', 'input', 'update:selected']);

let preventChangeEmit = false;

function keyDown() {
  if (props.type === 'date') {
    preventChangeEmit = true;
  }
}

function keyUp() {
  if (props.type === 'date') {
    preventChangeEmit = false;
  }
}

function onChanged(e: InputEvent): void {
  if (preventChangeEmit) {
    return;
  }

  let targetValue = (e.target as HTMLFormElement).value;

  if (props.max !== null && Number(targetValue) > Number(props.max)) {
    (e.target as HTMLFormElement).value = props.max;
    targetValue = props.max;
  }

  if (props.min !== null && Number(targetValue) < Number(props.min)) {
    (e.target as HTMLFormElement).value = props.min;
    targetValue = props.min;
  }

  emit('update:modelValue', targetValue);
}

const hasIcon = computed(() => {
  return !!slots['icon'];
});

const { writeTextToClipboard } = useClipboard();

const { emitToastEvent } = useToast();

function copyInputValue(e: MouseEvent): void {
  e.preventDefault();

  writeTextToClipboard(input.value.value);

  emitToastEvent({
    message: 'Value copied to the clipboard.',
    variant: ToastVariant.Info,
  });
}

const hasHelpText = computed(() => {
  return !!props.helpText;
});

function onSelectionChange(event: Event) {
  const targetValue = (event.target as HTMLSelectElement).value;

  emit('update:selected', targetValue);
}

const step = computed(() => {
  if (props.type === FieldTypes.Number && props.allowDecimal) {
    return 'any';
  }

  return null;
});

const colorPickerClass = computed(() => {
  if (props.type === TextInputFieldTypes.Color) {
    return 'disabled:opacity-50';
  }

  return '';
});

watch(() => props.selected, () => {
  const event = new Event('input');

  input.value.dispatchEvent(event);
});

const errorIcon = ref(null);
</script>

<template>
  <div class="w-full">
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="elementId"
      :label-hidden="labelHidden"
      :flush="hasHelpText"
    />

    <HelpText v-if="helpText">
      {{ helpText }}
    </HelpText>

    <div class="relative">
      <div class="flex">
        <div
          data-test="text-input"
          class="relative flex items-center w-full focus-within:outline-none focus:outline-none rounded-md border overflow-hidden bg-white"
          :class="[borderColorClass, theme('shadow')]"
        >
          <div class="absolute right-3">
            <ExclamationCircleIcon
              v-if="error"
              ref="errorIcon"
              class="h-5 w-5 fill-red-500"
            />
          </div>

          <div
            data-test="text-input-icon"
            class="absolute text-bg-slate text-slate-400 flex justify-center items-center h-full"
            :class="iconPositionClass"
          >
            <slot
              name="icon"
            />
          </div>

          <input
            :id="elementId"
            ref="input"
            :type="type"
            :min="min"
            :max="max"
            :step="step"
            :required="required"
            :name="name"
            class="w-full block appearance-none placeholder-slate-400 disabled:bg-slate-50 disabled:cursor-not-allowed border-none outline-none text-slate-700"
            :class="[
              inputTextSizeClass,
              inputPaddingClass,
              inputIconPaddingClass,
              InputFontFamilyClass,
              inputMarginClass,
              colorPickerClass,
            ]"
            :aria-controls="ariaControls"
            :aria-disabled="disabled"
            :autofocus="autofocus"
            :disabled="disabled"
            :maxlength="maxLength"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            tabindex="0"
            :value="modelValue"
            v-bind="$attrs"
            @keydown="keyDown"
            @keyup="keyUp"
            @blur="onChanged"
            @input="onChanged"
          >
        </div>

        <div
          v-if="hasAddon"
          data-test="text-input-addon"
          class="px-4 rounded rounded-l-none bg-slate-100 flex items-center border border-l-0 border-slate-300 text-sm"
        >
          <slot name="addon" />
        </div>
      </div>

      <div
        v-if="allowCopy"
        class="absolute top-1/2 -translate-y-1/2 right-2 h-4 w-4"
      >
        <button
          class="text-slate-500 hover:text-slate-600 cursor-pointer"
          @click="copyInputValue"
        >
          <DocumentDuplicateIcon class="h-full w-full" />
        </button>
      </div>

      <div
        v-if="selectable"
        class="absolute inset-y-0 right-0 flex items-center"
      >
        <select
          :value="selected"
          class="h-full w-12 bg-transparent text-slate-400 p-1 rounded-md font-medium appearance-none outline-none border-transparent"
          :class="borderColorClass"
          @change="onSelectionChange"
        >
          <option
            v-for="(option, index) in options"
            :key="index"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <ErrorMessage
      v-if="error && !showTooltipError"
      :message="error"
    />

    <Tooltip
      v-if="showTooltipError"
      :content="error"
      :children="errorIcon"
    />
  </div>
</template>
