<script lang="ts" setup>
import { computed } from 'vue';
import { SectionSpacing } from '../types';

const props = withDefaults(defineProps<{
  spacing?: SectionSpacing;
}>(), {
  spacing: SectionSpacing.Default,
});

const spacingClass = computed(() => {
  switch (props.spacing) {
    case SectionSpacing.Large:
      return 'space-y-12';
    case SectionSpacing.Default:
    default:
      return 'space-y-6';
  }
});
</script>

<template>
  <section :class="spacingClass">
    <slot />
  </section>
</template>
