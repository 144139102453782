import { asset } from 'laravel-vapor';

function replaceWithFallback(el: HTMLImageElement): void {
  const fallbackSrc = asset('img/default.png');

  if (!el.src) {
    el.src = fallbackSrc;
  }

  el.onerror = () => {
    el.src = fallbackSrc;
  };
}

export default {
  mounted: (el: HTMLImageElement) => replaceWithFallback(el),
  beforeUpdate: (el: HTMLImageElement) => replaceWithFallback(el),
};
