export enum TextStyleFontSize {
  Default = 'default',
  Small = 'small',
  Large = 'large',
  Base = 'base',
  ExtraLarge = 'extra-large',
  Huge = 'huge',
}

export enum TextStyleShade {
  Pale = 'pale',
  Default = 'default',
  Subdued = 'subdued',
  Dark = 'dark',
}

export enum TextStyleTracking {
  Default = 'default',
  Wide = 'wide',
}
