import {
  readonly,
  ref,
} from 'vue';
export function useClipboard() {
  const clipboardText = ref(null);

  function writeTextToClipboard(value: string): void {
    const isClipboardAccessible = navigator.clipboard && window.isSecureContext;

    if (isClipboardAccessible) {
      navigator.clipboard.writeText(value)
        .then((response) => clipboardText.value = response)
        .catch(() => clipboardText.value = '');
    }
  }

  return {
    clipboardText: readonly(clipboardText),
    writeTextToClipboard,
  };
}
