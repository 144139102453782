<script lang="ts" setup>
import {
  computed,
  useSlots,
} from 'vue';
import { InputLabel } from '../../InputLabel';
import { HelpText } from '../../HelpText';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { ErrorMessage } from '../../ErrorMessage';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { theme } from '../../../Composables/useTheme';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },

  id: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    default: null,
  },

  placeholder: {
    type: String,
    default: null,
  },

  required: {
    type: Boolean,
    default: false,
  },

  error: {
    type: String,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();

const emit = defineEmits([
  'update:modelValue',
]);

function onChange(e: InputEvent): void {
  emit('update:modelValue', (e.target as HTMLFormElement).value);
}

const elementId = computed(() => props.id || useUniqueId('textField'));

const hasHelpText = computed(() => {
  return !!slots['help-text'];
});

const classList = computed(() => {
  return [
    { 'cursor-not-allowed': props.disabled },
    { 'pr-10': props.error },
    theme([
      'border',
      'outline',
    ], props.error ? 'critical' : 'default'),
  ];
});
</script>

<template>
  <div class="relative">
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="elementId"
      :flush="hasHelpText"
    />

    <HelpText v-if="hasHelpText">
      <slot name="help-text" />
    </HelpText>

    <div class="relative">
      <ExclamationCircleIcon
        v-if="error"
        class="absolute right-3 -translate-y-1/2 top-1/2 h-5 w-5 fill-red-500"
      />

      <input
        :id="elementId"
        type="text"
        tabindex="0"
        :value="modelValue"
        class="border w-full px-3 py-2 appearance-none rounded-md text-sm  shadow-sm"
        :class="classList"
        :placeholder="placeholder"
        v-bind="$attrs"
        :required="required"
        :disabled="disabled"
        @input="onChange"
      >
    </div>

    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>
