<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import { ListStyle } from '../types';

export default defineComponent({
  props: {
    flush: {
      type: Boolean,
      default: false,
    },

    style: {
      type: String,
      validator: (val: ListStyle): boolean => Object.values(ListStyle).includes(val),
      default: ListStyle.Disc,
    },

    markerSubdued: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const paddingClass = computed(() => {
      return !props.flush ? 'pl-4' : '';
    });

    const styleClass = computed(() => {
      return props.style === ListStyle.Disc ? 'list-disc' : 'list-none';
    });

    const marker = computed(() => {
      return props.markerSubdued ? 'marker:text-slate-400' : '';
    });

    const classList = computed(() => {
      return [].concat(paddingClass.value, styleClass.value, marker.value);
    });

    return {
      classList,
    };
  },
});
</script>

<template>
  <ul
    data-test="list"
    class="list-outside"
    :class="classList"
  >
    <slot />
  </ul>
</template>
