<script lang="ts">
export default {
  props: {
    accessibilityLabel: {
      type: String,
      default: null,
    },
  },
};
</script>

<template>
  <div
    data-test="spinner"
    class="spinner"
    role="status"
    :aria-label="accessibilityLabel"
  >
    <svg
      width="20"
      height="20"
      stroke-width="16.00"
      viewBox="-3.00 -3.00 106.00 106.00"
    >
      <path
        class="track"
        d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
      />
      <path
        class="head"
        d="M 50,50 m 0, -45 a 45, 45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
        pathLength="280"
        stroke-dasharray="280 280"
        stroke-dashoffset="210"
      />
    </svg>
  </div>
</template>

<style scoped>

.spinner {
  display: inline-flex;
  animation: spinner 0.5s linear infinite;
}

svg {
  display: block;
}

path {
  fill-opacity: 0;
}

.track {
  stroke: rgba(0, 0, 0, 0.15);
}

.head {
  stroke: white;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  transform-origin: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>
