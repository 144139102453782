import Vapor from 'laravel-vapor';

export type VaporUploadedFile = {
  uuid: string;
  bucket: string;
  key: string;
  url: string;
  headers: string[];
  extension: string;
};

export function useUpload() {
  function upload(file: File, progress?: (progress: number) => void): Promise<VaporUploadedFile> {
    return Vapor.store(file, {
      visibility: 'public-read',
      progress,
    });
  }

  return { upload };
}
