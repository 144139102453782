export enum ToastVariant {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Critical = 'critical',
}

export interface ToastAction {
  label: string;
  url: string;
}
