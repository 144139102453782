<script lang="ts" setup>
import { computed, h, useSlots } from 'vue';
import { TextContainerSpacing } from '../types';

const props = defineProps({
  spacing: {
    type: String,
    validator: (val: TextContainerSpacing): boolean =>
      Object.values(TextContainerSpacing).includes(val),
    default: null,
  },

  textCenter: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();

const classList = computed(() => {
  return [
    'text-sm text-slate-700',
    { 'text-center': props.textCenter },
    { 'space-y-1': props.spacing === TextContainerSpacing.ExtraTight },
    { 'space-y-2': props.spacing === TextContainerSpacing.Tight },
    { 'space-y-4': props.spacing === TextContainerSpacing.Loose },
  ];
});

const element = computed<string>(() => {
  return props.spacing !== null ? 'div' : 'span';
});

const TextContainer = () => {
  return h(
    'div',
    {
      'data-test': 'text-container',
      'class': classList.value,
    },
    slots.default().map((slot) => h(element.value, slot)),
  );
};
</script>

<template>
  <TextContainer />
</template>
