<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import { StatusIndicatorSize } from '../types';

export default defineComponent({
  props: {
    color: {
      type: String,
      default: null,
    },

    size: {
      type: String,
      validator: (val: StatusIndicatorSize): boolean =>
        Object.values(StatusIndicatorSize).includes(val),
      default: StatusIndicatorSize.Default,
    },

    ring: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const sizeClasses = computed(() => {
      switch (props.size) {
        case StatusIndicatorSize.Small:
          return 'h-2.5 w-2.5';
        case StatusIndicatorSize.Default:
          return 'h-3 w-3';
        case StatusIndicatorSize.Large:
          return 'h-4 w-4';
      }

      return '';
    });

    const colorClass = computed(() => `bg-${props.color}-300`);

    const ringClass = computed(() => (props.ring ? 'ring-2 ring-white' : ''));

    return { sizeClasses, colorClass, ringClass };
  },
});
</script>

<template>
  <span
    data-test="status-indicator"
    class="block rounded-full"
    :class="[sizeClasses, colorClass, ringClass]"
  />
</template>
