<script lang="ts" setup>
</script>

<template>
  <div
    data-test="help-text"
    class="text-xs leading-5 text-slate-400 mb-1"
  >
    <slot />
  </div>
</template>
