const themeValues = {
  'border': {
    default: 'border-slate-300',
    critical: 'border-red-500',
  },

  'outline': {
    default: 'outline-blue-500',
    critical: 'outline-red-500',
  },

  'notification-duration': {
    default: 3000,
  },
};

export function theme(value: string | Array<string>, variant = 'default'): string {
  if (Array.isArray(value)) {
    return value
      .map((v) => theme(v, variant))
      .join(' ');
  }

  return themeValues[value][variant];
}
