import { ReturnOrderQuestionCondition } from '@app/customer/types';
import { endsWith, get, includes, isArray, isNil, isString, startsWith } from 'lodash';

export enum ConditionComparator {
  INCLUDES = 'INCLUDES',
  NOT_INCLUDES = 'NOT_INCLUDES',
  EQUALS = 'EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  NOT_EQUALS = 'NOT_EQUALS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
}

export enum ConditionOperator {
  OR = 'OR',
  AND = 'AND',
}

export const ConditionSubOperator = {
  EQUALS: ConditionOperator.OR,
  INCLUDES: ConditionOperator.OR,
  NOT_EQUALS: ConditionOperator.AND,
  NOT_INCLUDES: ConditionOperator.AND,
};

export class ConditionListParser {
  static fromReturnQuestionConditions(conditions: ReturnOrderQuestionCondition[]): ConditionList {
    return new ConditionList(conditions.map((condition: ReturnOrderQuestionCondition) => {
      const input = 'meta_key' in condition && typeof condition.meta_key === 'string'
        ? condition.input + '.' + condition.meta_key.toLowerCase()
        : condition.input;

      return new Condition(
        input,
        condition.value,
        ConditionComparator[condition.comparator],
        ConditionOperator[condition.operator],
        condition.subSelection,
      );
    }));
  }
}

export class ConditionList {
  constructor(
    private conditions: Array<Condition>,
  ) {
  }

  getGroups(): Record<number, Condition[]> {
    const groups = {};
    let group = [];
    let groupKey = 0;

    this.conditions.forEach((condition: Condition, key: number) => {
      group.push(condition);

      if (condition.operator === ConditionOperator.OR && this.conditions.length !== key + 1) {
        return;
      }

      groups[groupKey++] = group;
      group = [];
    });

    return groups;
  }
}

export class Condition {
  constructor(
    public readonly input: number | string,
    public readonly value: number | string | unknown[],
    public readonly comparator: ConditionComparator,
    public readonly operator: ConditionOperator,
    public readonly subSelection?: number | string | unknown[],
  ) {
  }

  private compare(comparableInput: any, value: any): boolean {
    comparableInput = typeof comparableInput === 'string' ? comparableInput.toLowerCase() : comparableInput;
    value = typeof value === 'string' ? value.toLowerCase() : value;

    if (isNil(comparableInput) || isNil(value)) {
      return false;
    }

    switch (this.comparator) {
      case ConditionComparator.EQUALS:
        return comparableInput.toString() === value.toString();
      case ConditionComparator.GREATER_THAN:
        return parseFloat(comparableInput) > parseFloat(value);
      case ConditionComparator.GREATER_THAN_EQUAL:
        return parseFloat(comparableInput) >= parseFloat(value);
      case ConditionComparator.INCLUDES:
        return includes(comparableInput, value);
      case ConditionComparator.LESS_THAN:
        return parseFloat(comparableInput) < parseFloat(value);
      case ConditionComparator.LESS_THAN_EQUAL:
        return parseFloat(comparableInput) <= parseFloat(value);
      case ConditionComparator.NOT_EQUALS:
        return comparableInput.toString() !== value.toString();
      case ConditionComparator.NOT_INCLUDES:
        return !includes(comparableInput, value);
      case ConditionComparator.STARTS_WITH:
        return startsWith(comparableInput, value);
      case ConditionComparator.ENDS_WITH:
        return endsWith(comparableInput, value);
    }
  }

  evaluateBySubOperator(comparisons) {
    if (ConditionSubOperator[this.comparator] === ConditionOperator.AND) {
      return comparisons.every(Boolean);
    }

    if (ConditionSubOperator[this.comparator] === ConditionOperator.OR) {
      return comparisons.some(Boolean);
    }

    return false;
  }

  getAnsweredQuestion(value, attributes) {
    value = String(value).replace('return_question.', 'answers.');

    const comparableInput = get(attributes, value);

    value = this.subSelection;

    return [comparableInput, value];
  }

  evaluateQuestionAnswered(attributes) {
    let value = this.value;

    let comparableInput;

    if (isArray(value)) {
      const comparisons = [];

      value.forEach((item, index) => {
        [comparableInput, value] = this.getAnsweredQuestion(item, attributes);

        if (comparableInput && isString(comparableInput)) {
          comparisons.push(this.compare(comparableInput, value[index]));
        }

        if (comparableInput && isArray(comparableInput)) {
          comparableInput.forEach((input) => {
            comparisons.push(this.compare(input, value[index]));
          });
        }
      });

      return this.evaluateBySubOperator(comparisons);
    }

    [comparableInput, value] = this.getAnsweredQuestion(value, attributes);

    if (isArray(comparableInput)) {
      const comparisons = [];

      comparableInput.forEach((input) => {
        comparisons.push(this.compare(input, value));
      });

      return this.evaluateBySubOperator(comparisons);
    }

    return this.compare(comparableInput, value);
  }

  evaluate(attributes: object): boolean {
    const input = this.input;
    const value = this.value;

    const comparableInput = get(attributes, input);

    if (input === 'question_answered') {
      return this.evaluateQuestionAnswered(attributes);
    }

    if (isArray(comparableInput) && !isArray(value)) {
      const results = [];

      comparableInput.forEach((compare) => {
        const comparableKey = comparableInput.indexOf(compare);
        results.push(this.compare(comparableInput[comparableKey], value));
      });

      return this.evaluateBySubOperator(results);
    }

    if (isArray(value) && !isArray(comparableInput)) {
      const results = [];

      value.forEach((item) => {
        const itemKey = (value as unknown[]).indexOf(item);
        results.push(this.compare(comparableInput, value[itemKey]));
      });

      return this.evaluateBySubOperator(results);
    }

    if (isArray(comparableInput) && isArray(value)) {
      const outerResult = [];

      comparableInput.forEach((compare) => {
        const innerResult = [];

        (value as unknown[]).forEach((item) => {
          const itemKey = (value as unknown[]).indexOf(item);
          innerResult.push(this.compare(compare, value[itemKey]));
        });

        outerResult.push(this.evaluateBySubOperator(innerResult));
      });

      return this.evaluateBySubOperator(outerResult);
    }

    if (isNil(comparableInput) || isNil(value)) {
      return false;
    }

    return this.compare(comparableInput, value);
  }
}

export class ConditionEvaluator {
  constructor(private readonly conditionList: ConditionList) {
    // ...
  }

  evaluate(attributes: object): boolean {
    const groups = this.conditionList.getGroups();
    const evaluatedGroups = [];

    Object.values(groups).forEach((group: Array<Condition>): void => {
      const groupResult = group
        .map((condition: Condition): boolean => condition.evaluate(attributes))
        .filter(Boolean)
        .length > 0;

      evaluatedGroups.push(groupResult);
    });

    return evaluatedGroups.every(Boolean);
  }
}
