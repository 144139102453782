import axios, { AxiosInstance } from 'axios';
import { getEnv } from '@app/shared/Utils';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { useToast } from './useToast';
import { ToastVariant } from '../Components';
import i18n from '../Utils/I18n';
import { has } from 'lodash';

const language = computed<string>(() => {
  return usePage().props.language as string;
});

const isOnline = computed<boolean>(() => {
  return navigator.onLine;
});

function handleError(error) {
  const { t } = i18n(language.value).global;
  const { emitToastEvent } = useToast();

  const handleErrorGlobally = has(error.config, 'handleErrorGlobally')
    ? error.config.handleErrorGlobally
    : true;

  if (isOnline.value === false) {
    emitToastEvent({
      title: t('panel.global:offline'),
      message: t('panel.global:internet-disconnected'),
      variant: ToastVariant.Critical,
    });

    return Promise.reject(error);
  }

  if (handleErrorGlobally) {
    const responseData = error.response?.data;

    const action = responseData?.action ?? null;
    const message = responseData?.message ?? t('panel.global:error-message');

    emitToastEvent({
      action,
      message,
      variant: ToastVariant.Critical,
      duration: action ? 0 : null,
    });

    return Promise.reject(error);
  }

  return Promise.reject(error);
}

function setupInterceptor(instance: AxiosInstance): void {
  instance.interceptors.response.use(
    (response) => response,
    (error) => handleError(error),
  );
}

export function useApi(): AxiosInstance {
  const page = usePage().props;

  const axiosInstance = axios.create({
    baseURL: `${getEnv('api_url')}/${page.currentApiVersion}/`,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
      common: {
        rpak: page.rpak,
      },
    },
  });

  setupInterceptor(axiosInstance);

  return axiosInstance;
}

export function usePanel(): AxiosInstance {
  const axiosInstance = axios.create({
    withCredentials: true,
    withXSRFToken: true,
  });

  setupInterceptor(axiosInstance);

  return axiosInstance;
}
