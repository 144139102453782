<script lang="ts" setup>
import {
  computed,
  PropType,
} from 'vue';
import {
  ButtonBorder,
  ButtonIconPosition,
  ButtonRounded,
  ButtonType,
  ButtonVariant,
} from '../types';
import { Spinner } from '../../Spinner';
import { theme } from '../../../Composables/useTheme';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
  variant: {
    type: String,
    validator: (val: ButtonVariant): boolean =>
      Object.values(ButtonVariant).includes(val),
    default: ButtonVariant.Default,
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },

  iconPosition: {
    type: String,
    validator: (val: ButtonIconPosition): boolean =>
      Object.values(ButtonIconPosition).includes(val),
    default: ButtonIconPosition.Left,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  plain: {
    type: Boolean,
    default: false,
  },

  href: {
    type: String,
    default: null,
  },

  border: {
    type: String,
    validator: (val: ButtonBorder): boolean =>
      Object.values(ButtonBorder).includes(val),
    default: null,
  },

  rounded: {
    type: String,
    validator: (val: ButtonRounded): boolean =>
      Object.values(ButtonRounded).includes(val),
    default: ButtonRounded.Default,
  },

  flush: {
    type: Boolean,
    default: false,
  },

  type: {
    type: String as PropType<ButtonType>,
    validator: (val: ButtonType): boolean =>
      Object.values(ButtonType).includes(val),
    default: ButtonType.Button,
  },

  form: {
    type: String,
    default: null,
  },

  alignLeft: {
    type: Boolean,
    default: false,
  },
});

const backgroundColor = computed(() => {
  if (props.plain) {
    return 'bg-transparent';
  }

  return [
    { 'bg-white': props.variant === ButtonVariant.Default },
    { 'bg-brand-500': props.variant === ButtonVariant.Primary },
    { 'bg-red-600': props.variant === ButtonVariant.Critical },
  ];
});

const backgroundHoverColor = computed(() => {
  if (props.disabled) {
    return '';
  }

  if (props.plain) {
    return 'hover:underline';
  }

  return [
    { 'hover:bg-slate-100': props.variant === ButtonVariant.Default },
    { 'hover:bg-brand-600': props.variant === ButtonVariant.Primary },
    { 'hover:bg-red-700': props.variant === ButtonVariant.Critical },
  ];
});

const borderColor = computed(() => {
  if (props.plain) {
    return 'border-0';
  }

  return [
    { 'border-slate-300': props.variant === ButtonVariant.Default },
    { 'border-brand-500': props.variant === ButtonVariant.Primary },
    { 'border-red-600': props.variant === ButtonVariant.Critical },
  ];
});

const textColor = computed(() => {
  if (props.plain) {
    return [
      { 'text-slate-600': props.variant === ButtonVariant.Default },
      { 'text-brand-500': props.variant === ButtonVariant.Primary },
      { 'text-red-600': props.variant === ButtonVariant.Critical },
    ];
  }

  if (props.variant === ButtonVariant.Default) {
    return 'text-slate-800';
  }

  return 'text-white';
});

const focusRingClass = computed(() => {
  if (props.plain) {
    return '';
  }

  return [
    theme('focus-ring-size'),
    theme('focus-ring-offset'),
    { [theme('focus-ring-color', 'primary')]: props.variant === ButtonVariant.Primary },
    { [theme('focus-ring-color', 'critical')]: props.variant === ButtonVariant.Critical },
  ];
});

const classList = computed(() => {
  return [
    [
      'inline-flex',
      'items-center',
      'text-sm',
      'font-medium',
      'border',
      'focus:outline-none',
      'focus:relative',
    ],

    { 'justify-start': props.alignLeft },
    { 'justify-center': !props.alignLeft },

    { 'px-4 py-2': !props.plain && !props.flush },
    { 'px-1 py-2': props.flush },

    { 'rounded-md': props.rounded === ButtonRounded.Default },
    { 'rounded-l-md': props.rounded === ButtonRounded.Left },
    { 'rounded-r-md': props.rounded === ButtonRounded.Right },

    { 'border-r-0': props.border === ButtonBorder.RightNone },
    { 'border-l-0': props.border === ButtonBorder.LeftNone },
    { 'border-0': props.border === ButtonBorder.None },

    { 'shadow-sm': !props.plain },
    { 'w-full': props.fullWidth },

    { 'opacity-50 cursor-not-allowed': props.disabled },

    { 'pointer-events-none opacity-75': props.loading },
  ];
});

const iconClassList = computed(() => {
  return [
    'w-5',
    'h-5',
    { 'mr-2 order-first': props.iconPosition === ButtonIconPosition.Left },
    { 'ml-2 order-last': props.iconPosition === ButtonIconPosition.Right },
    { 'mx-auto': props.iconPosition === ButtonIconPosition.Center },
  ];
});

const componentType = props.href ? Link : 'button';
</script>

<template>
  <component
    :is="componentType"
    :class="
      [
        backgroundColor,
        backgroundHoverColor,
        borderColor,
        classList,
        focusRingClass,
        textColor,
      ]"
    :disabled="disabled || loading"
    :type="type"
    :form="form"
    :href="href"
  >
    <div
      data-test="button-body"
      class="inline-flex justify-center items-center"
      :class="loading && !plain ? 'invisible' : ''"
    >
      <span
        v-if="!!$slots['icon']"
        data-test="button-icon"
        :class="iconClassList"
      >
        <slot name="icon" />
      </span>

      <slot />
    </div>

    <Spinner
      v-if="loading && !plain"
      data-test="button-spinner"
      class="absolute"
    />
  </component>
</template>
