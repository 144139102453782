<script lang="ts" setup>
import { computed } from 'vue';
import { AvatarSize } from '../types';

const props = withDefaults(defineProps<{
  size?: AvatarSize;
  src?: string | null;
  rounded?: boolean;
  ring?: boolean;
}>(), {
  size: AvatarSize.Default,
  src: null,
  rounded: false,
  ring: false,
});

const classList = computed((): (string | object)[] => {
  return [
    { 'h-8 w-8': props.size === AvatarSize.Default },
    { 'h-10 w-10': props.size === AvatarSize.Large },
    { 'h-12 w-12': props.size === AvatarSize.ExtraLarge },
    { 'rounded-full': props.rounded },
    { 'ring-2 ring-white': props.ring },
  ];
});
</script>

<template>
  <div class="relative">
    <img
      class="inline-block object-contain"
      :class="classList"
      :src="src"
      loading="lazy"
    >

    <span
      class="absolute bottom-1 left-1 block translate-y-1/2 -translate-x-1/2 transform"
    >
      <slot name="status-indicator" />
    </span>
  </div>
</template>
