const themeValues = {
  'border': {
    default: 'border-slate-300',
    critical: 'border-red-500',
  },

  'border-size': {
    default: 'border',
  },

  'focus-border': {
    default: 'focus:border-brand-500',
    critical: 'focus:border-red-500',
  },

  'focus-within-border-color': {
    default: 'focus-within:border-brand-500',
    critical: 'focus-within:border-red-500',
  },

  'hover-border': {
    default: 'hover:border-slate-400',
    critical: 'hover:border-red-400',
  },

  'focus-within-ring-color': {
    default: 'focus-within:ring-blue-300',
    critical: 'focus-within:ring-red-300',
  },

  'focus-within-ring-size': {
    default: 'focus-within:ring-2',
  },

  'focus-ring-color': {
    default: 'focus:ring-blue-300',
    primary: 'focus:ring-brand-300',
    critical: 'focus:ring-red-300',
  },

  'focus-ring-size': {
    default: 'focus:ring-2',
  },

  'focus-ring-offset': {
    default: 'focus:ring-offset-2',
  },

  'focus-outline-color': {
    default: 'focus:outline-blue-300',
    critical: 'focus:outline-red-300',
  },

  'hover-bg': {
    default: 'hover:bg-slate-50',
    primary: 'hover:bg-brand-50',
    critical: 'hover:bg-red-50',
  },

  'notification-duration': {
    default: 3000,
  },

  'shadow': {
    default: 'shadow-sm',
  },
};

export function theme(value: string | Array<string>, variant = 'default'): string {
  if (Array.isArray(value)) {
    return value
      .map((v) => theme(v, variant))
      .join(' ');
  }

  return themeValues[value][variant];
}
