<script lang="ts" setup>
import { computed, PropType } from 'vue';
import {
  TextStyleFontSize,
  TextStyleShade,
  TextStyleTracking,
} from '../types';
import * as Types from '@app/panel/types/generated';

const props = defineProps({
  fontSize: {
    type: String,
    validator: (val: TextStyleFontSize): boolean =>
      Object.values(TextStyleFontSize).includes(val),
    default: TextStyleFontSize.Default,
  },

  color: {
    type: String as PropType<Types.App.Enums.ColorPalette>,
    default: 'slate',
  },

  strong: {
    type: Boolean,
    default: false,
  },

  bold: {
    type: Boolean,
    default: false,
  },

  semibold: {
    type: Boolean,
    default: false,
  },

  truncate: {
    type: Boolean,
    default: false,
  },

  shade: {
    type: String,
    validator: (val: TextStyleShade): boolean =>
      Object.values(TextStyleShade).includes(val),
    default: TextStyleShade.Default,
  },

  tracking: {
    type: String as PropType<TextStyleTracking>,
    validator: (val: TextStyleTracking): boolean =>
      Object.values(TextStyleTracking).includes(val),
    default: TextStyleTracking.Default,
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },

  noWrap: {
    type: Boolean,
    default: false,
  },
});

const classList = computed(() => {
  return {
    'text-xs': props.fontSize === TextStyleFontSize.Small,
    'text-sm': props.fontSize === TextStyleFontSize.Default,
    'text-base': props.fontSize === TextStyleFontSize.Base,
    'text-lg': props.fontSize === TextStyleFontSize.Large,
    'text-xl': props.fontSize === TextStyleFontSize.ExtraLarge,
    'text-2xl': props.fontSize === TextStyleFontSize.Huge,
    [`text-${props.color}-400`]: props.shade === TextStyleShade.Pale,
    [`text-${props.color}-500`]: props.shade === TextStyleShade.Subdued,
    [`text-${props.color}-700`]: props.shade === TextStyleShade.Default,
    [`text-${props.color}-900`]: props.shade === TextStyleShade.Dark,
    'tracking-normal': props.tracking === TextStyleTracking.Default,
    'tracking-wide': props.tracking === TextStyleTracking.Wide,
    'font-medium': props.strong,
    'font-bold': props.bold,
    'font-semibold': props.semibold,
    'truncate': props.truncate,
    'w-full': props.fullWidth,
    'whitespace-nowrap': props.noWrap,
  };
});
</script>

<template>
  <span :class="classList">
    <slot />
  </span>
</template>
