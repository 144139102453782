<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },

  labelFor: {
    type: String,
    default: null,
  },

  labelHidden: {
    type: Boolean,
    default: false,
  },

  subdued: {
    type: Boolean,
    default: false,
  },

  flush: {
    type: Boolean,
    default: false,
  },
});

const classList = computed(() => {
  return [
    { 'font-medium text-slate-700': !props.subdued },
    { 'text-slate-500': props.subdued },
    { 'mb-1': !props.flush },
  ];
});
</script>

<template>
  <label
    v-if="!labelHidden"
    class="block w-fit cursor-pointer text-sm"
    :class="classList"
    :for="labelFor"
  >
    {{ label }}
  </label>
</template>
