<script lang="ts">
import {
  computed,
  defineComponent,
  h,
  PropType,
} from 'vue';
import { PageActionsPosition } from '../types';

export default defineComponent({
  props: {
    position: {
      type: String as PropType<PageActionsPosition>,
      validator: (val: PageActionsPosition) =>
        Object.values(PageActionsPosition).includes(val),
      default: PageActionsPosition.Between,
    },
  },

  setup(props, { slots }) {
    const classList = computed(() => {
      return [
        'mb-6',
        'flex',
        'items-stretch',
        'space-x-6',
        { 'justify-between': props.position === PageActionsPosition.Between },
        { 'justify-start': props.position === PageActionsPosition.Start },
        { 'justify-end': props.position === PageActionsPosition.End },
      ];
    });

    return () => h('div', {
      'data-test': 'page-actions-container', 'class': classList.value,
    }, slots.default().map((slot) => {
      return h('div', { 'data-test': 'page-actions-item',
        'class': 'flex space-x-2 whitespace-nowrap' }, slot.children);
    }));
  },
});
</script>
