<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {
  TextStyleFontSize,
  TextStyleShade,
  TextStyleTracking,
  TextStyleTransform,
} from '../types';
import * as Types from '@app/panel/types/generated';

export default defineComponent({
  props: {
    fontSize: {
      type: String,
      validator: (val: TextStyleFontSize): boolean =>
        Object.values(TextStyleFontSize).includes(val),
      default: TextStyleFontSize.Default,
    },

    color: {
      type: String as PropType<Types.App.Enums.ColorPalette>,
      default: 'slate',
    },

    block: {
      type: Boolean,
      default: false,
    },

    strong: {
      type: Boolean,
      default: false,
    },

    semiBold: {
      type: Boolean,
      default: false,
    },

    bold: {
      type: Boolean,
      default: false,
    },

    truncate: {
      type: Boolean,
      default: false,
    },

    shade: {
      type: String,
      validator: (val: TextStyleShade): boolean =>
        Object.values(TextStyleShade).includes(val),
      default: TextStyleShade.Default,
    },

    tracking: {
      type: String as PropType<TextStyleTracking>,
      validator: (val: TextStyleTracking): boolean =>
        Object.values(TextStyleTracking).includes(val),
      default: TextStyleTracking.Default,
    },

    transform: {
      type: String as PropType<TextStyleTransform>,
      validator: (val: TextStyleTransform): boolean =>
        Object.values(TextStyleTransform).includes(val),
      default: TextStyleTransform.Default,
    },

    inlineBlock: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    noWrap: {
      type: Boolean,
      default: false,
    },

    fontMono: {
      type: Boolean,
      default: false,
    },

    textCenter: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const classList = computed(() => {
      return {
        'block': props.block,
        'text-base': props.fontSize === TextStyleFontSize.Base,
        'text-xs': props.fontSize === TextStyleFontSize.Small,
        'text-sm': props.fontSize === TextStyleFontSize.Default,
        'text-lg': props.fontSize === TextStyleFontSize.Large,
        [`text-${props.color}-400`]: props.shade === TextStyleShade.Pale,
        [`text-${props.color}-500`]: props.shade === TextStyleShade.Subdued,
        [`text-${props.color}-700`]: props.shade === TextStyleShade.Default,
        [`text-${props.color}-900`]: props.shade === TextStyleShade.Dense,
        'tracking-normal': props.tracking === TextStyleTracking.Default,
        'tracking-wide': props.tracking === TextStyleTracking.Wide,
        'normal-case': props.transform === TextStyleTransform.Default,
        'uppercase': props.transform === TextStyleTransform.Uppercase,
        'capitalize': props.transform === TextStyleTransform.Capitalize,
        'font-medium': props.strong,
        'font-semibold': props.semiBold,
        'font-bold': props.bold,
        'truncate': props.truncate,
        'inline-block': props.inlineBlock,
        'w-full': props.fullWidth,
        'whitespace-nowrap': props.noWrap,
        'font-mono': props.fontMono,
        'text-center': props.textCenter,
      };
    });

    return {
      classList,
    };
  },
});
</script>

<template>
  <span
    data-test="text-style"
    :class="classList"
  >
    <slot />
  </span>
</template>
