<script lang="ts" setup>
import { computed, PropType } from 'vue';
import CardSection from './CardSection.vue';
import { ButtonGroup } from '../../ButtonGroup';
import { FooterActionPosition, PrimaryFooterAction, SecondaryFooterAction } from '../types';
import { Button, ButtonType, ButtonVariant } from '../../Button';

const props = defineProps({
  primaryAction: {
    type: Object as PropType<PrimaryFooterAction>,
    default: null,
  },

  secondaryActions: {
    type: Array as PropType<SecondaryFooterAction[]>,
    default: null,
  },

  footerActionPosition: {
    type: String as PropType<FooterActionPosition>,
    validator: (val: FooterActionPosition) =>
      Object.values(FooterActionPosition).includes(val),
    default: FooterActionPosition.Right,
  },

  isFooterSlotPresent: {
    type: Boolean,
    default: false,
  },

  footerHidden: {
    type: Boolean,
    default: false,
  },
});

const defaultButton = ButtonVariant.Default;
const primaryButton = ButtonVariant.Primary;

const buttonGroupPositionClasses = computed(() => {
  return [
    'flex',
    { 'justify-end': props.footerActionPosition === FooterActionPosition.Right },
    { 'justify-start': props.footerActionPosition === FooterActionPosition.Left },
  ];
});
</script>

<template>
  <CardSection
    v-if="isFooterSlotPresent && !footerHidden"
    subdued
    dense
  >
    <slot />
  </CardSection>

  <CardSection
    v-if="(primaryAction || secondaryActions) && !footerHidden"
    class="rounded-b-lg"
    subdued
    dense
  >
    <div :class="buttonGroupPositionClasses">
      <ButtonGroup :justify-between="footerActionPosition === FooterActionPosition.Between">
        <Button
          v-for="(secondaryFooterAction, index) in secondaryActions"
          :key="index"
          :variant="secondaryFooterAction.variant ? secondaryFooterAction.variant : defaultButton"
          :plain="secondaryFooterAction.plain ? secondaryFooterAction.plain : false"
          :disabled="secondaryFooterAction.disabled"
          :loading="secondaryFooterAction.loading"
          @click="secondaryFooterAction.callback"
        >
          {{ secondaryFooterAction.content }}
        </Button>

        <Button
          v-if="primaryAction"
          :variant="primaryButton"
          :loading="primaryAction.loading"
          :type="primaryAction.type ?? ButtonType.Button"
          :disabled="primaryAction.disabled"
          @click="primaryAction.callback"
        >
          {{ primaryAction.content }}
        </Button>
      </ButtonGroup>
    </div>
  </CardSection>
</template>
