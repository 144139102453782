import { ChartColor } from '@app/panel/Components/Charts/config';

export default {
  animation: false,
  animations: {
    duration: 0,
  },
  datasets: {
    bar: {
      minBarLength: 0,
    },
  },
  interaction: {
    intersect: false,
    mode: 'nearest',
    axis: 'x',
  },
  plugins: {
    legend: {
      align: 'end',
      labels: {
        font: {
          size: 14,
        },
        padding: 24,
        pointStyle: 'circle',
        usePointStyle: true,
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    x: {
      grid: {
        color: ChartColor.Slate100,
        borderColor: ChartColor.Slate200,
        display: false,
        drawTicks: false,
      },

      ticks: {
        autoSkip: false,
        maxRotation: 0,
        color: ChartColor.Slate500,
        callback: function (val, index, data) {
          return index === 0 || index === data.length - 1 ? this.getLabelForValue(val) : '';
        },
        font: {
          size: 14,
        },
        padding: 15,
        align: 'inner',
      },
    },
    y: {
      grid: {
        color: ChartColor.Slate100,
        borderColor: ChartColor.Slate200,
        drawTicks: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};
