export enum ChartColor {
  Slate700 = '#334155',
  Slate500 = '#64748b',
  Slate400 = '#94a3b8',
  Slate200 = '#e2e8f0',
  Slate100 = '#f1f5f9',
}

export const options = {
  events: [],

  layout: {
    padding: 10,
  },

  elements: {
    point: {
      radius: 0,
    },

    line: {
      borderWidth: 1.5,
    },
  },

  interaction: {
    intersect: false,
    mode: 'nearest',
    axis: 'x',
  },

  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },

  animation: {
    duration: 0,
  },

  scales: {
    x: {
      grid: {
        color: ChartColor.Slate100,
        borderColor: ChartColor.Slate200,
        display: false,
        drawTicks: false,
      },

      ticks: {
        autoSkip: false,
        maxRotation: 0,
        color: ChartColor.Slate500,
        callback: function (val, index, data) {
          return index === 0 || index === data.length - 1 ? this.getLabelForValue(val) : '';
        },
        padding: 15,
      },
    },

    y: {
      grid: {
        color: ChartColor.Slate100,
        borderColor: ChartColor.Slate200,
        display: false,
        drawTicks: false,
      },

      ticks: {
        display: true,
      },
    },
  },
};

/*
{
  backgroundColor: <tailwind-color>-400,
  hoverBackgroundColor: <tailwind-color>-500,
  activeColor: <tailwind-color>-300,
}
*/
export const chartColors = [
  // brand
  {
    backgroundColor: '#5555da',
    hoverBackgroundColor: '#2b2bd1',
    activeColor: '#8080e3',
  },

  // blue
  {
    backgroundColor: '#60a5fa',
    hoverBackgroundColor: '#3b82f6',
    activeColor: '#93c5fd',
  },

  // purple
  {
    backgroundColor: '#c084fc',
    hoverBackgroundColor: '#a855f7',
    activeColor: '#d8b4fe',
  },

  // red
  {
    backgroundColor: '#f87171',
    hoverBackgroundColor: '#ef4444',
    activeColor: '#fca5a5',
  },

  // yellow
  {
    backgroundColor: '#facc15',
    hoverBackgroundColor: '#eab308',
    activeColor: '#fde047',
  },

  // green
  {
    backgroundColor: '#4ade80',
    hoverBackgroundColor: '#22c55e',
    activeColor: '#86efac',
  },
];

/* <tailwind-color>-300 */
export const doughnutBackgroundColors = [
  '#8080e3', // brand
  '#93c5fd', // blue
  '#d8b4fe', // purple
  '#fda4af', // red
  '#fde047', // yellow
  '#86efac', // green
  '#7dd3fc', // sky
  '#c4b5fd', // violet
  '#f9a8d4', // pink
  '#fdba74', // orange
  '#bef264', // lime
  '#67e8f9', // cyan
];
