<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <div
    data-test="error-message"
    class="error-message mt-2"
  >
    <p class="text-sm text-red-600">
      {{ message }}
    </p>
  </div>
</template>
