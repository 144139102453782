<script lang="ts" setup>
import { computed } from 'vue';
import { BannerVariant } from '../types';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/vue/24/solid';

const props = defineProps({
  variant: {
    type: String,
    validator: (val: BannerVariant): boolean =>
      Object.values(BannerVariant).includes(val),
    default: BannerVariant.Success,
  },

  title: {
    type: String,
    default: null,
  },
});

const icon = computed(() => {
  switch (props.variant) {
    case BannerVariant.Info:
      return InformationCircleIcon;
    case BannerVariant.Success:
      return CheckCircleIcon;
    case BannerVariant.Warning:
      return ExclamationTriangleIcon;
    case BannerVariant.Critical:
      return ExclamationCircleIcon;
    case BannerVariant.Default:
    default:
      return InformationCircleIcon;
  }
});

const variantColor = computed(() => {
  switch (props.variant) {
    case BannerVariant.Info:
      return 'blue';
    case BannerVariant.Success:
      return 'green';
    case BannerVariant.Warning:
      return 'yellow';
    case BannerVariant.Critical:
      return 'red';
    case BannerVariant.Default:
    default:
      return 'gray';
  }
});

const backgroundColor = computed(() => `bg-${variantColor.value}-50`);
const textColor = computed(() => `text-${variantColor.value}-800`);
const fillColor = computed(() => `fill-${variantColor.value}-400`);
</script>

<template>
  <div
    data-test="banner-container"
    class="banner flex md:items-center rounded-md p-4"
    :class="backgroundColor"
  >
    <div>
      <component
        :is="icon"
        class="h-5 w-5"
        :class="fillColor"
      />
    </div>

    <div
      class="ml-3 text-sm w-full"
      data-test="banner-body"
    >
      <h3
        class="font-medium"
        :class="textColor"
      >
        {{ title }}
      </h3>

      <div
        :class="[title ? 'mt-2' : '', textColor]"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
