export enum StackDistribution {
  EqualSpacing = 'equal-spacing',
  Leading = 'leading',
  Trailing = 'trailing',
  Center = 'center',
  Fill = 'fill',
  FillEvenly = 'fill-evenly',
}

export enum StackAlignment {
  Fill = 'fill',
  Leading = 'leading',
  Trailing = 'trailing',
  Center = 'center',
}

export enum StackSpacing {
  ExtraTight = 'extra-tight',
  Tight = 'tight',
  BaseTight = 'base-tight',
  Loose = 'loose',
  ExtraLoose = 'extra-loose',
  None = 'none',
}
