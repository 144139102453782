<script lang="ts">
import {
  computed,
  defineComponent,
  h,
  PropType,
  VNode,
} from 'vue';
import {
  StackAlignment,
  StackDistribution,
  StackSpacing,
} from '../types';

export default defineComponent({
  props: {
    distribution: {
      type: String as PropType<StackDistribution>,
      default: StackDistribution.Leading,
      validator: (val: string): boolean =>
        Object.values(StackDistribution).includes(val as StackDistribution),
    },

    alignment: {
      type: String as PropType<StackAlignment>,
      default: StackAlignment.Fill,
      validator: (val: string): boolean =>
        Object.values(StackAlignment).includes(val as StackAlignment),
    },

    spacing: {
      type: String as PropType<StackSpacing>,
      default: StackSpacing.BaseTight,
      validator: (val: string): boolean =>
        Object.values(StackSpacing).includes(val as StackSpacing),
    },

    divide: {
      type: Boolean,
      default: false,
    },

    gap: {
      type: [String, null],
      default: null,
    },

    wrap: {
      type: Boolean,
      default: false,
    },

    vertical: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { slots }) {
    const axis = computed(() => (props.vertical ? 'b' : 'r'));
    const spacing = computed(() => {
      return [
        { [`p${axis.value}-1`]: props.spacing === StackSpacing.ExtraTight },
        { [`p${axis.value}-2`]: props.spacing === StackSpacing.Tight },
        { [`p${axis.value}-3`]: props.spacing === StackSpacing.BaseTight },
        { [`p${axis.value}-4`]: props.spacing === StackSpacing.Loose },
        { [`p${axis.value}-8`]: props.spacing === StackSpacing.ExtraLoose },
      ];
    });

    const distribution = computed(() => {
      return [
        { 'justify-between': props.distribution == StackDistribution.EqualSpacing },
        { 'justify-start': props.distribution == StackDistribution.Leading },
        { 'justify-end': props.distribution == StackDistribution.Trailing },
        { 'justify-center': props.distribution == StackDistribution.Center },
        { 'justify-around': props.distribution == StackDistribution.Fill },
        { 'justify-evenly': props.distribution == StackDistribution.FillEvenly },
      ];
    });

    const alignment = computed(() => {
      return [
        { 'items-stretch': props.alignment === StackAlignment.Fill },
        { 'items-start': props.alignment === StackAlignment.Leading },
        { 'items-end': props.alignment === StackAlignment.Trailing },
        { 'items-center': props.alignment === StackAlignment.Center },
      ];
    });

    const direction = computed(() => {
      return props.vertical === true ? 'flex-col' : 'flex-row';
    });

    const divide = computed(() => {
      return [{ [`divide-${props.vertical ? 'y' : 'x'}`]: props.divide }];
    });

    const gap = computed(() => {
      return `gap-${props.gap}`;
    });

    const wrap = computed(() => {
      return props.wrap ? 'flex-wrap' : '';
    });

    const classList = computed(() => {
      return ['flex', direction.value, distribution.value, divide.value, gap.value, alignment.value, wrap.value];
    });

    const lastIndex = computed(() => {
      return slots.default().length > 1 ? slots.default().length - 1 : slots.default()[0].children;
    });

    return () => h('div', { 'data-test': 'stack', 'class': classList.value }, slots.default()
      .map((element: VNode, index: number) => {
        return h(element, { class: lastIndex.value === index ? '' : [spacing.value] });
      }),
    );
  },
});
</script>
