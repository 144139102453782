<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { Spinner } from '../../Spinner';
import { type ButtonColor } from '../types';
import { Link } from '@inertiajs/vue3';
import { theme } from '../../../Composables/useTheme';

const props = defineProps({
  color: {
    type: Object as PropType<ButtonColor>,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  plain: {
    type: Boolean,
    default: false,
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },

  href: {
    type: String,
    default: null,
  },

  noWrap: {
    type: Boolean,
    default: false,
  },
});

const colors = computed(() => {
  if (!props.color) {
    return {
      '--color': '#334155',
      '--background-color': '#fff',
      '--border': props.plain ? 'none' : '1px solid #cbd5e1',
      '--background-color-hover': '#f8fafc',
    };
  }

  if (props.plain) {
    return {
      '--color': props.color.background,
    };
  }

  return {
    '--color': props.color.foreground,
    '--background-color': props.color.background,
    '--border': `1px solid ${props.color.border}`,
    '--background-color-hover': props.disabled ? props.color.background : props.color.hover,
  };
});

const classList = computed(() => {
  return [
    { 'w-full': props.fullWidth },
    { 'border-0': props.plain },
    { 'hover:underline': props.plain },
    { 'px-3 py-2': !props.plain },
    { border: !props.plain && !props.color },
    theme('outline'),
    { 'opacity-50 cursor-not-allowed': props.disabled },
  ];
});

const componentType = computed(() => {
  return props.href ? Link : 'button';
});
</script>

<template>
  <component
    :is="componentType"
    :href="! disabled ? href : '#'"
    :disabled="disabled || loading"
    class="appearance-none inline-flex items-center justify-center rounded-md text-sm"
    :class="classList"
    :style="colors"
    v-bind="$attrs"
  >
    <span
      class="inline-flex justify-center items-center"
      :class="{
        'invisible' : loading,
        'pointer-events-none': disabled,
        'whitespace-nowrap': noWrap,
      }"
    >
      <slot />
    </span>

    <Spinner
      v-if="loading"
      data-test="button-spinner"
      class="absolute"
    />
  </component>
</template>

<style scoped>
button {
  color: var(--color);
  background-color: var(--background-color);
  border: var(--border);
}

button:hover {
  background-color: var(--background-color-hover);
  border: var(--border);
}
</style>
