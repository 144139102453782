<script lang="ts" setup>
import { TextStyle, TextStyleShade } from '../../TextStyle';
import { computed, PropType } from 'vue';
import { SimpleTableElement } from '../types';

const props = defineProps({
  element: {
    type: String as PropType<SimpleTableElement>,
    validator: (val: SimpleTableElement): boolean =>
      Object.values(SimpleTableElement).includes(val),
    default: SimpleTableElement.Data,
  },

  subdued: {
    type: Boolean,
    default: false,
  },

  colspan: {
    type: [Number, null],
    default: null,
  },
});

const element = computed(() => {
  switch (props.element) {
    case SimpleTableElement.Header:
      return 'th';
    case SimpleTableElement.Data:
    default:
      return 'td';
  }
});
</script>

<template>
  <component
    :is="element"
    class="text-left py-2.5 px-6"
    :class="{ 'bg-slate-50': subdued }"
    :colspan="colspan"
  >
    <TextStyle :shade="TextStyleShade.Dense">
      <slot />
    </TextStyle>
  </component>
</template>
