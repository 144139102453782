<script lang="ts" setup>
const emit = defineEmits<{
  (e: 'submit', htmlFormElement: HTMLFormElement): void;
}>();

function onSubmit(e: HTMLFormElement): void {
  emit('submit', e);
}
</script>

<template>
  <form
    v-bind="$attrs"
    @submit.prevent="onSubmit"
  >
    <slot />
  </form>
</template>
