import colors from 'tailwindcss/colors';
import tailwindConfig from '@root/tailwind.config.cjs';

// Remove deprecated colors to silence the warnings
delete colors.lightBlue;
delete colors.warmGray;
delete colors.trueGray;
delete colors.coolGray;
delete colors.blueGray;

// Extend the tailwind colors with the custom colors from our config
const allColors = {
  ...colors,
  ...tailwindConfig.theme.extend.colors,
};

type TailwindColorMap = typeof allColors;

type TailwindColor = keyof {
  [K in keyof TailwindColorMap as TailwindColorMap[K] extends object ? K : never]: TailwindColorMap[K];
};

type TailwindVariant<T extends TailwindColor> = keyof TailwindColorMap[T];

export function useTailwindColor<T extends TailwindColor>(color: T, variant: TailwindVariant<T> & string): TailwindColorMap[T][TailwindVariant<T>] {
  if (!allColors[color]) {
    throw new Error(`Color '${color}' does not exist in the Tailwind CSS color palette.`);
  }

  return allColors[color][variant];
}
