<script lang="ts" setup>
import {
  computed,
  PropType,
  useSlots,
} from 'vue';
import CardFooter from './CardFooter.vue';
import CardSection from './CardSection.vue';
import {
  Badge,
  BadgeRounded,
} from '../../Badge';
import { Heading } from '../../Heading';
import { TextContainer } from '../../TextContainer';
import {
  TextStyle,
  TextStyleShade,
} from '../../TextStyle';
import {
  PrimaryFooterAction,
  SecondaryFooterAction,
} from '../types';
import { StatusBadge } from '@app/panel/types';
import {
  CardShadow,
  FooterActionPosition,
} from '../types';

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: null,
  },

  subtitle: {
    type: String,
    required: false,
    default: null,
  },

  shadow: {
    type: String as PropType<CardShadow>,
    validator: (val: CardShadow) =>
      Object.values(CardShadow).includes(val),
    default: CardShadow.Default,
  },

  statusBadges: {
    type: Array as PropType<StatusBadge[]>,
    default: null,
  },

  primaryFooterAction: {
    type: Object as PropType<PrimaryFooterAction>,
    default: null,
  },

  secondaryFooterActions: {
    type: Array as PropType<SecondaryFooterAction[]>,
    default: null,
  },

  footerActionPosition: {
    type: String as PropType<FooterActionPosition>,
    validator: (val: FooterActionPosition) =>
      Object.values(FooterActionPosition).includes(val),
    default: FooterActionPosition.Right,
  },

  footerHidden: {
    type: Boolean,
    default: false,
  },

  divide: {
    type: Boolean,
    default: true,
  },
});

const classList = computed(() => {
  return [
    { 'shadow-sm': props.shadow === CardShadow.Default },
    { 'shadow-xl': props.shadow === CardShadow.ExtraLarge },
    { 'divide-y divide-slate-200': props.divide },
  ];
});

const slots = useSlots();

const isFooterSlotPresent = computed(() => {
  return !!slots['footer'];
});
</script>

<template>
  <div
    data-test="card"
    class="border bg-white rounded-lg overflow-auto"
    :class="classList"
  >
    <div
      v-if="title"
      data-test="card-body"
    >
      <CardSection class="pb-0">
        <div class="flex">
          <Heading>{{ title }}</Heading>

          <div class="ml-4 space-x-2">
            <Badge
              v-for="(badge, index) in statusBadges"
              :key="index"
              :color="badge.color"
              :rounded="BadgeRounded.Full"
            >
              {{ badge.label }}
            </Badge>
          </div>
        </div>

        <div
          v-if="subtitle"
          class="mt-1"
        >
          <TextContainer>
            <TextStyle :shade="TextStyleShade.Subdued">
              {{ subtitle }}
            </TextStyle>
          </TextContainer>
        </div>
      </CardSection>

      <slot />
    </div>
    <slot v-if="!title" />

    <CardFooter
      :primary-action="primaryFooterAction"
      :secondary-actions="secondaryFooterActions"
      :footer-action-position="footerActionPosition"
      :is-footer-slot-present="isFooterSlotPresent"
      :footer-hidden="footerHidden"
    >
      <slot name="footer" />
    </CardFooter>
  </div>
</template>
