<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  fullWidth: {
    type: Boolean,
    default: false,
  },

  justifyBetween: {
    type: Boolean,
    default: false,
  },
});

const classList = computed(() => {
  return [
    { 'w-full flex gap-3': props.fullWidth },
    { 'space-x-3': !props.fullWidth },
    { 'w-full flex justify-between': props.justifyBetween },
  ];
});
</script>

<template>
  <div
    data-test="button-group"
    :class="classList"
  >
    <slot />
  </div>
</template>
