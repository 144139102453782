export default [
  /WKWebView/i,
  /jquery/i,
  /ResizeObserver/i,
  /pushState/i,
  /fbq/i,
  /google/i,
  /Page not found/i,
  /Load failed/i,
];
