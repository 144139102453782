import { type UploadedFileInterface } from './types';
import { useUpload, VaporUploadedFile } from '@app/panel/Composables/useUpload';
import { useToast } from '@app/panel/Composables/useToast';
import { ToastVariant } from '@app/panel/Components';
const { upload } = useUpload();
const { emitToastEvent } = useToast();

export class UploadedFile implements UploadedFileInterface {
  file: File;
  id: string;
  name: string;
  url: string;
  progress: number | null;
  type: string;
  size: number;

  constructor(file: File) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.name = file.name;
    this.url = URL.createObjectURL(file);
    this.progress = null;
    this.type = file.type;
    this.size = file.size;
  }

  public async upload(): Promise<VaporUploadedFile> {
    try {
      return await upload(this.file, (progress) => this.progress = progress);
    } catch (error) {
      emitToastEvent({
        title: 'Failed to upload file',
        message: error,
        variant: ToastVariant.Critical,
      });
    }
  }
}
