export enum TextStyleFontSize {
  Default = 'default',
  Small = 'small',
  Large = 'large',
  Base = 'base',
}

export enum TextStyleShade {
  Default = 'default',
  Pale = 'pale',
  Subdued = 'subdued',
  Dense = 'dense',
}

export enum TextStyleTracking {
  Default = 'default',
  Wide = 'wide',
}

export enum TextStyleTransform {
  Default = 'default',
  Uppercase = 'uppercase',
  Capitalize = 'capitalize',
}
