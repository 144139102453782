<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import { InputLabel } from '../../InputLabel';
import { HelpText } from '../../HelpText';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { theme } from '../../../Composables/useTheme';
import ErrorMessage from '@app/panel/Components/ErrorMessage/components/ErrorMessage.vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },

  id: {
    type: String,
    default: null,
  },

  name: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    default: null,
  },

  labelHidden: {
    type: Boolean,
    default: false,
  },

  helpText: {
    type: String,
    default: null,
  },

  rows: {
    type: Number,
    default: 3,
  },

  placeholder: {
    type: String,
    default: null,
  },

  error: {
    type: String,
    default: null,
  },

  required: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const elementId = computed(() => props.id || useUniqueId('textArea'));

function onChanged(e: InputEvent): void {
  emit('update:modelValue', (e.target as HTMLFormElement).value);
}

const classList = computed(() => {
  return theme([
    'outline',
    'border',
  ],
  props.error ? 'critical' : 'default',
  );
});

const slots = useSlots();

const hasHelpText = computed<boolean>(() => {
  return !!props.helpText || !!slots['help-text'];
});
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="elementId"
      :label-hidden="labelHidden"
      :flush="hasHelpText"
    />

    <HelpText v-if="hasHelpText">
      <slot name="help-text">
        {{ helpText }}
      </slot>
    </HelpText>

    <div class="relative">
      <textarea
        :id="elementId"
        :name="name"
        :rows="rows"
        class="relative shadow-sm block w-full text-sm border rounded-md p-2"
        :class="classList"
        :placeholder="placeholder"
        :value="modelValue"
        :required="required"
        @input="onChanged"
      />

      <div
        data-test="text-input-icon"
        class="z-50 right-3 top-2 absolute text-bg-slate text-slate-400 flex justify-center items-center"
      >
        <slot name="icon" />
      </div>
    </div>

    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>
