export enum BadgeSize {
  Small = 'small',
  Large = 'large',
}

export enum BadgeFontSize {
  Default = 'default',
  SemiBold = 'semi-bold',
}

export enum BadgeRounded {
  Default = 'default',
  Full = 'full',
}
