<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { HeadingElement, HeadingSize, HeadingWeight } from '../types';

const props = defineProps({
  element: {
    type: String,
    validator: (val: HeadingElement): boolean =>
      Object.values(HeadingElement).includes(val),
    default: HeadingElement.H2,
  },

  size: {
    type: String,
    validator: (val: HeadingSize): boolean =>
      Object.values(HeadingSize).includes(val),
    default: HeadingSize.Medium,
  },

  weight: {
    type: String as PropType<HeadingWeight>,
    default: HeadingWeight.Medium,
    validator: (val: HeadingWeight): boolean =>
      Object.values(HeadingWeight).includes(val),
  },
});

const sizeClass = computed(() => {
  if (props.size === HeadingSize.Small) {
    return 'text-sm';
  }

  if (props.size === HeadingSize.Medium) {
    return 'text-lg';
  }

  if (props.size === HeadingSize.Large) {
    return 'text-2xl';
  }

  return '';
});

const weightClass = computed(() => {
  if (props.weight === HeadingWeight.Medium) {
    return 'font-medium';
  }

  if (props.weight === HeadingWeight.Bold) {
    return 'font-bold';
  }

  return '';
});

const classList = computed(() => {
  return [
    sizeClass.value,
    weightClass.value,
  ];
});
</script>

<template>
  <component
    :is="element"
    :class="classList"
    class="text-slate-900 leading-6"
  >
    <slot />
  </component>
</template>
