<script lang="ts" setup>
const props = withDefaults(defineProps<{
  label: string;
  labelFor?: string;
  labelHidden?: boolean;
  flush?: boolean;
}>(), {
  labelFor: null,
  labelHidden: false,
  flush: false,
});
</script>

<template>
  <label
    v-if="!labelHidden"
    class="block w-fit cursor-pointer text-sm font-medium text-slate-700 truncate"
    :class="{ 'mb-1 ': !props.flush }"
    :for="labelFor"
  >
    {{ label }}
  </label>
</template>
