<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { PopoverPlacement } from '../types';

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },

  placement: {
    type: String as PropType<PopoverPlacement>,
    validation: (val: PopoverPlacement) =>
      Object.values(PopoverPlacement).includes(val),
    default: PopoverPlacement.BottomStart,
  },
});

const placementClass = computed<string>(() => {
  switch (props.placement) {
    case PopoverPlacement.BottomEnd:
      return 'right-0';
    case PopoverPlacement.BottomStart:
    default:
      return 'left-0';
  }
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function closePopover() {
  if (!props.open) {
    return;
  }

  emit('close');
}
</script>

<template>
  <div
    v-if="open"
    class="z-50 absolute mt-0.5"
    :class="placementClass"
  >
    <div
      v-click-away="closePopover"
      class="overflow-hidden border-slate-300 rounded-lg border bg-white shadow-lg py-2"
    >
      <slot />
    </div>
  </div>
</template>
