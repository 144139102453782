export enum ButtonVariant {
  Default = 'default',
  Primary = 'primary',
  Critical = 'critical',
}

export enum ButtonIconPosition {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export enum ButtonType {
  Submit = 'submit',
  Button = 'button',
}

export enum ButtonBorder {
  RightNone = 'right-none',
  LeftNone = 'left-none',
  None = 'none',
}

export enum ButtonRounded {
  Default = 'default',
  Left = 'left',
  Right = 'right',
}
