<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';

export default defineComponent({
  props: {
    subdued: {
      default: false,
      type: Boolean,
    },

    dense: {
      default: false,
      type: Boolean,
    },

    flush: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const classList = computed(() => {
      return {
        'bg-slate-50': props.subdued,
        'px-0': (!props.dense && props.flush),
        'py-3': (props.dense && !props.flush),
        'py-6': (!props.dense && !props.flush),
      };
    });

    return {
      classList,
    };
  },
});
</script>

<template>
  <div
    class="px-4 sm:px-6"
    :class="classList"
  >
    <slot />
  </div>
</template>
