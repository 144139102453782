export enum FieldTypes {
  Email = 'email',
  Number = 'number',
  Password = 'password',
  Search = 'search',
  Tel = 'tel',
  Text = 'text',
  URL = 'url',
  Date = 'date',
  Month = 'month',
  Color = 'color',
}

export enum AutocompleteOption {
  Off = 'off',
  On = 'on',
  Email = 'email',
  NewPassword = 'new-password',
  CurrentPassword = 'current-password',
  OneTimeCode = 'one-time-code',
  Organization = 'organization',
}

export enum InputTextSize {
  Small = 'small',
  Large = 'large',
}

export enum InputIconPosition {
  Left = 'left',
  Right = 'right',
}

export enum InputFontFamily {
  Sans = 'font-sans',
  Mono = 'font-mono',
}
