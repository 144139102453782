<script lang="ts">
import {
  computed,
  defineComponent,
  h,
} from 'vue';
import { TextContainerSpacing } from '../types';

export default defineComponent({
  props: {
    spacing: {
      type: String,
      validator: (val: TextContainerSpacing): boolean =>
        Object.values(TextContainerSpacing).includes(val),
      default: TextContainerSpacing.Tight,
    },

    textCenter: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { slots }) {
    const classList = computed(() => {
      return [
        'text-sm',
        'text-slate-700',
        { 'text-center': props.textCenter },
        { 'space-y-0.5': props.spacing === TextContainerSpacing.Minimal },
        { 'space-y-1': props.spacing === TextContainerSpacing.ExtraTight },
        { 'space-y-2': props.spacing === TextContainerSpacing.Tight },
        { 'space-y-4': props.spacing === TextContainerSpacing.Loose },
        { 'space-y-8': props.spacing === TextContainerSpacing.ExtraLoose },
      ];
    });

    return () =>
      h(
        'div',
        { class: classList.value },
        slots.default().map((slot) => h('div', slot)),
      );
  },
});
</script>
