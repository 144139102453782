<script lang="ts" setup>
import {
  computed,
  PropType,
} from 'vue';
import { OverlayShade } from '../types';

const props = defineProps({
  transparent: {
    type: Boolean,
    default: false,
  },

  shade: {
    type: String as PropType<OverlayShade>,
    validator: (val: OverlayShade) =>
      Object.values(OverlayShade).includes(val),
    default: OverlayShade.Default,
  },
});

const classList = computed(() => {
  return [
    { 'bg-slate-500': props.shade === OverlayShade.Default && !props.transparent },
    { 'bg-slate-500/75': props.shade === OverlayShade.Default && props.transparent },
    { 'bg-slate-100': props.shade === OverlayShade.Light && !props.transparent },
    { 'bg-slate-100/75': props.shade === OverlayShade.Light && props.transparent },
  ];
});
</script>

<template>
  <div
    data-test="overlay"
    class="z-40 fixed inset-0 h-full w-full overflow-y-auto overflow-x-hidden"
    :class="classList"
  >
    <div class="flex justify-center items-center min-h-full z-50 p-4">
      <slot />
    </div>
  </div>
</template>
