<script lang="ts" setup>
import { createPopper, Instance } from '@popperjs/core';
import {
  computed,
  onUnmounted,
  ref,
  watchEffect,
} from 'vue';

const props = withDefaults(defineProps<{
  content?: string;
  children?: HTMLElement | null;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}>(), {
  content: null,
  children: null,
  placement: 'top',
});

const active = ref(false);
const child = ref(null);
const popperInstance = ref(null);
const tooltip = ref(null);

const arrowPlacementClass = computed(() => {
  switch (props.placement) {
    case 'top':
      return '-bottom-1';

    case 'right':
      return '-left-1';

    case 'bottom':
      return '-top-1';

    case 'left':
      return '-rigth-1';

    default:
      return '';
  }
});

const visibilityClass = computed(() => {
  return active.value ? 'block' : 'hidden';
});

const eventListeners = {
  mouseenter: show,
  mouseleave: hide,
  focusin: show,
  focusout: hide,
};

function show() {
  active.value = true;

  popperInstance.value.update();
}

function hide() {
  active.value = false;
}

function createPopperInstance(child: HTMLElement): Instance {
  return createPopper(child, tooltip.value, {
    placement: props.placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 15],
        },
      },
    ],
  });
}

watchEffect(() => {
  if (!props.children) {
    popperInstance.value = createPopperInstance(child.value);
    return;
  }

  popperInstance.value = createPopperInstance(props.children);

  Object.entries(eventListeners).forEach(([eventName, handler]) => {
    props.children.addEventListener(eventName, handler);
  });
});

onUnmounted(() => {
  if (!props.children) {
    return;
  }

  Object.entries(eventListeners).forEach(([eventName, handler]) => {
    props.children.removeEventListener(eventName, handler);
  });
});
</script>

<template>
  <div
    v-if="content || $slots['content']"
    ref="tooltip"
    class="tooltip w-fit bg-slate-900 text-white text-sm font-medium py-1 px-2 rounded-md max-w-sm whitespace-normal text-center z-[999]"
    :class="visibilityClass"
  >
    <slot name="content">
      <span>{{ content }}</span>
    </slot>
    <div
      id="arrow"
      :class="arrowPlacementClass"
      data-popper-arrow
    />
  </div>

  <span
    ref="child"
    v-on="!props.children ? eventListeners : {}"
  >
    <slot />
  </span>
</template>

<style scoped>
#arrow {
  visibility: hidden;
}

#arrow, #arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
</style>
