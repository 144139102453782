import { ref } from 'vue';
import { router } from '@inertiajs/vue3';

const loading = ref(false);

router.on('start', () => loading.value = true);
router.on('finish', () => loading.value = false);

export function usePageLoad() {
  return { loading };
}
