<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import { IconSize } from '../types';
import * as Types from '@app/panel/types/generated';

export default defineComponent({
  props: {
    lastItem: {
      type: Boolean,
      default: false,
    },

    date: {
      type: String,
      default: null,
    },

    iconRounded: {
      type: Boolean,
      default: false,
    },

    iconSize: {
      type: String as PropType<IconSize>,
      validator: (val: IconSize): boolean =>
        Object.values(IconSize).includes(val),
      default: IconSize.Default,
    },

    iconBackgroundColor: {
      type: String as PropType<Types.App.Enums.ColorPalette>,
      default: null,
    },

    iconRingColor: {
      type: String as PropType<Types.App.Enums.ColorPalette>,
      default: null,
    },

    separationLineColor: {
      type: String as PropType<Types.App.Enums.ColorPalette>,
      default: 'slate',
    },
  },

  setup(props, { slots }) {
    const classList = computed(() => {
      return [{ 'pb-8': !props.lastItem }];
    });

    const isIconPresent = computed(() => {
      return !!slots['icon'];
    });

    const iconClassList = computed(() => {
      return [
        props.iconBackgroundColor ? `bg-${props.iconBackgroundColor}-100` : 'bg-white',
        props.iconBackgroundColor ? `text-${props.iconBackgroundColor}-600` : 'text-black',
        props.iconRingColor ? `ring-${props.iconRingColor}-500` : 'ring-white',
        { 'h-6 w-6': props.iconSize === IconSize.Default },
        { 'h-8 w-8': props.iconSize === IconSize.Large },
      ];
    });

    const separationLineClassList = computed(() => {
      return [
        `bg-${props.separationLineColor}-200`,
        { 'left-3': props.iconSize === IconSize.Default },
        { 'left-4': props.iconSize === IconSize.Large },
      ];
    });

    return {
      classList,
      iconClassList,
      isIconPresent,
      separationLineClassList,
    };
  },
});
</script>

<template>
  <div
    data-test="timeline-item"
    class="relative"
    :class="classList"
  >
    <span
      v-if="!lastItem"
      class="absolute top-4 -ml-px h-full w-0.5"
      :class="separationLineClassList"
      aria-hidden="true"
    />

    <div class="relative flex space-x-3">
      <div class="w-8">
        <div
          v-if="isIconPresent"
          class="flex h-8 w-8 items-center justify-center ring-2 rounded-full"
          :class="iconClassList"
        >
          <slot name="icon" />
        </div>
      </div>

      <div class="flex min-w-0 flex-1 items-center justify-between space-x-4">
        <slot />

        <div
          v-if="date"
          class="whitespace-nowrap text-right text-xs text-slate-400"
        >
          <time>{{ date }}</time>
        </div>
      </div>
    </div>
  </div>
</template>
