<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import { BannerVariant } from '../types';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/24/solid';

export default defineComponent({
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XMarkIcon,
  },

  props: {
    variant: {
      type: String,
      validator: (val: BannerVariant): boolean =>
        Object.values(BannerVariant).includes(val),
      default: BannerVariant.Success,
    },

    title: {
      type: String,
      default: null,
    },

    align: {
      type: String<'left' | 'center' | 'right'>,
      default: 'left',
    },

    open: {
      type: Boolean,
      default: true,
    },

    closeable: {
      type: Boolean,
      default: false,
    },

    hideIcon: {
      type: Boolean,
      default: false,
    },

    border: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  setup(props, { emit, slots }) {
    const icon = computed(() => {
      switch (props.variant) {
        case BannerVariant.Info:
          return 'InformationCircleIcon';
        case BannerVariant.Success:
          return 'CheckCircleIcon';
        case BannerVariant.Warning:
          return 'ExclamationTriangleIcon';
        case BannerVariant.Critical:
          return 'ExclamationCircleIcon';
        case BannerVariant.Default:
        default:
          return 'InformationCircleIcon';
      }
    });

    const variantColor = computed(() => {
      switch (props.variant) {
        case BannerVariant.Info:
          return 'blue';
        case BannerVariant.Success:
          return 'green';
        case BannerVariant.Warning:
          return 'yellow';
        case BannerVariant.Critical:
          return 'red';
        case BannerVariant.Default:
        default:
          return 'gray';
      }
    });

    const backgroundColor = computed(() => `bg-${variantColor.value}-50`);
    const textColor = computed(() => `text-${variantColor.value}-800`);
    const fillColor = computed(() => `fill-${variantColor.value}-400`);
    const hoverColor = computed(() => `hover:bg-${variantColor.value}-100`);
    const borderColor = computed(() => props.border ? `border border-${variantColor.value}-200` : '');

    const closeBanner = () => emit('close');

    function isDefaultSlotPresent() {
      return !!slots['default'];
    }

    return {
      backgroundColor,
      closeBanner,
      fillColor,
      hoverColor,
      icon,
      isDefaultSlotPresent,
      textColor,
      borderColor,
    };
  },
});
</script>

<template>
  <div
    v-if="open"
    data-test="banner-container"
    :class="`flex rounded-md p-4 items-center ${borderColor} ${backgroundColor}`"
  >
    <div
      v-if="! hideIcon"
      :data-test="icon"
    >
      <component
        :is="icon"
        class="h-5 w-5 mr-3"
        :class="fillColor"
      />
    </div>

    <div
      class="text-sm w-full"
      data-test="banner-body"
      :class="{
        [`text-${align}`]: true,
      }"
    >
      <h3
        class="font-medium"
        :class="textColor"
      >
        {{ title }}
      </h3>

      <div
        v-if="isDefaultSlotPresent()"
        :class="[title ? 'mt-2' : '', textColor]"
      >
        <slot />
      </div>
    </div>

    <div
      v-if="closeable"
      data-test="banner-close-wrapper"
      class="ml-auto pl-3"
    >
      <button
        type="button"
        class="inline-flex rounded-md p-1.5 focus:outline-none -mx-1.5 -my-1.5"
        :class="hoverColor"
        @click="closeBanner"
      >
        <XMarkIcon
          class="h-5 w-5"
          :class="fillColor"
        />
      </button>
    </div>
  </div>
</template>
