<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import { Link } from '@inertiajs/vue3';
import {
  LinkFontSize,
  LinkVariant,
} from '../types';
import { theme } from '../../../Composables/useTheme';

export default defineComponent({
  components: { Link },

  props: {
    href: {
      type: String,
      required: false,
      default: '#',
    },

    preserveState: {
      type: [Boolean, null],
      default: null,
    },

    preserveScroll: {
      type: [Boolean, null],
      default: null,
    },

    variant: {
      type: String,
      validator: (val: LinkVariant): boolean =>
        Object.values(LinkVariant).includes(val),
      default: LinkVariant.Default,
    },

    fontSize: {
      type: String,
      validator: (val: LinkFontSize): boolean =>
        Object.values(LinkFontSize).includes(val),
      default: LinkFontSize.Default,
    },

    underline: {
      type: Boolean,
      default: false,
    },

    native: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const classList = computed(() => {
      return [
        'group',
        'font-medium',
        'cursor-pointer',
        { 'text-md': props.fontSize === LinkFontSize.Default },
        { 'text-sm': props.fontSize === LinkFontSize.Small },
        { 'text-brand-500': props.variant === LinkVariant.Default },
        { [theme('focus-outline-color')]: props.variant === LinkVariant.Default },
        { 'text-red-500': props.variant === LinkVariant.Critical },
        { [theme('focus-outline-color', 'critical')]: props.variant === LinkVariant.Critical },
        { 'text-slate-700': props.variant === LinkVariant.Subdued },
        { [theme('focus-outline-color')]: props.variant === LinkVariant.Subdued },
        { 'hover:underline': props.underline },
      ];
    });

    const componentType = computed(() => {
      return props.native ? 'a' : Link;
    });

    return { classList, componentType };
  },
});
</script>

<template>
  <component
    :is="componentType"
    :class="classList"
    :href="href"
    :preserve-scroll="preserveScroll"
    :preserve-state="preserveState"
    data-test="link"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>
